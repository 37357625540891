<template>
  <b-row class="match-height">
    <b-col lg="12">
      <MerchantTerm class="mb-3" />
      <Footer />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import Footer from '@/components/basic/footer/Footer.vue'
import MerchantTerm from '@/components/basic/term/MerchantTerm.vue'

export default {
  components: {
    BRow,
    BCol,
    MerchantTerm,
    Footer,
  },
}
</script>
