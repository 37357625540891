<template>
  <div>
    <b-img
      src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fsincerely-media-_-hjiem5TqI-unsplash.jpg?alt=media&token=4ec45a13-a854-4dbd-b8a1-2b376ab31ce7"
      blank-color="#ccc"
      alt="placeholder"
      class="imgStyle_"
    />
    <div class="title_ text-center mt-3">
      特定商取引法に基づく表記
    </div>
    <div class="termsSpace mt-2">
      <b-card>
        <b-row
          v-for="(item, index) in terms"
          :key="index"
          class="rowSpace"
        >
          <b-col
            lg="4"
          >
            <div class="termText">
              {{ item.title }}
            </div>
          </b-col>
          <b-col>
            <div
              v-for="(p, q) in item.explain"
              :key="q"
              class="termExplain"
            >
              <span v-if="p.type === 1">
                {{ p.label }}
              </span>
              <span v-if="p.type === 2">
                こちらの
                <a class="text-primary">お問い合わせフォーム</a>
                からお問い合わせください。
              </span>
            </div>
          </b-col>
          <div class="underLine" />
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BImg, BRow, BCol, BCard,
} from 'bootstrap-vue'
/* eslint-disable */
import terms from '@/components/basic/term/特定商取引法に基づく表記.json'
/* eslint-enable */

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
  },
  directives: {
  },
  props: {
  },
  data() {
    return {
      terms,
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.imgStyle_ {
  width: 110%;
  height: 100px;
  object-fit: cover;
  margin: -5% -5% 0 -5%;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .imgStyle_ {
    width: 110%;
    height: 360px;
    object-fit: cover;
    margin: -5% -5% 0 -5%;
  }
}
.title_ {
  font-size: 1.3rem;
  font-weight: 600;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .title_ {
    font-size: 2.2rem;
    font-weight: 600;
  }
}
.termText {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0 0 2% 0;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .termText {
    font-size: 1rem;
    font-weight: 600;
  }
}
.termExplain {
  font-size: 0.8rem;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .termExplain {
    font-size: 1rem;
  }
}
.termsSpace {
  width: 95%;
  margin: 0 auto;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .termsSpace {
    width: 60%;
    margin: 0 auto;
  }
}
.rowSpace {
  margin: 3% 0;
}
.underLine {
  border-bottom: solid 0.5px #e6e6e6;
  width: 100%;
  margin: 2% 0 0 0;
}
</style>
